import React from "react"
import { useTranslation } from "react-i18next"

import HelpIcon from "../../images/help-2.svg"

import styles from "./contact-box.module.css"

export default function ContactBox({ children, title, buttonText }) {
  const { t } = useTranslation()

  const openBeacon = () => {
    window.Beacon("open")
  }

  return (
    <div className={`${styles.contactBox} row`}>
      <div className="col-md-12">
        <h1 className={`${styles.contactBoxTitle} text-center`}>{title}</h1>
        <div className="row">
          <div className="col-xs-12 col-md-8 col-lg-6 m-auto">
            <div className={styles.contactBoxText}>{children}</div>
            <button
              className={styles.contactBoxButton}
              type="button"
              onClick={openBeacon}
            >
              <img
                src={HelpIcon}
                alt={`${t("image.alt")} ${buttonText}`}
                className={styles.contactBoxButtonIcon}
              />
              <span className={styles.contactBoxButtonText}>{buttonText}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
